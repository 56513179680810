import { AnchorLink } from 'gatsby-plugin-anchor-links'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Scrollspy from 'react-scrollspy'
import AppStoreDarkLargeSVG from '../icons/appStoreDarkLargeSVG'
import Logo from '../icons/logo'
import StarHalfSVG from '../images/half-star.svg'
import StarSVG from '../images/star.svg'

function Header({ appStoreLink }) {
  // Nav open or not state
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <header
        className={`fixed inset-x-0 top-0  ${isOpen ? 'h-auto' : 'h-16'}`}
        id="top"
        style={{ zIndex: '1051' }}
      >
        <div className="relative z-50 bg-white border-bottom-grey ">
          <div className="flex items-center justify-between layout-space header-height">
            <AnchorLink to="/#home">
              <div className="flex items-center">
                <Logo className="logo-size" />
                <div>
                  <h3 className="font-title title-size bold">
                    VoxRec - Pаспознавание голоса в текст{' '}
                    <br className="lg:hidden" />
                    <span className="lg:hidden">
                      для iPhone, iPad и Apple Watch
                    </span>
                  </h3>
                  <div className="items-center hidden lg:flex ">
                    <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                    <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                    <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                    <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                    <img
                      src={StarHalfSVG}
                      alt="star"
                      className="w-4 h-4 mr-2px"
                    />
                    <p className="ml-2 font-title">4.7</p>
                  </div>
                </div>
              </div>
            </AnchorLink>

            <Scrollspy
              className="hidden md:flex md:items-center"
              componentTag="nav"
              items={['why', 'features', 'pricing', 'reviews', 'contact']}
              currentClassName="is-active"
              offset={-300}
            >
              <AnchorLink to="/#why" className="nav-link top-nav">
                Почему&nbsp;мы?
              </AnchorLink>
              <AnchorLink to="/#features" className="nav-link top-nav">
                Возможности
              </AnchorLink>
              <AnchorLink to="/#pricing" className="nav-link top-nav">
                Цены
              </AnchorLink>
              <AnchorLink to="/#reviews" className="nav-link top-nav">
                Отзывы
              </AnchorLink>
              <AnchorLink to="/#contact" className="nav-link top-nav">
                Контакты
              </AnchorLink>
              <a
                href={appStoreLink}
                className="hidden ml-6 lg:block"
                target="_blank"
                rel="noreferrer"
              >
                <AppStoreDarkLargeSVG className="app-store-link" />
              </a>
            </Scrollspy>

            <button
              onClick={() => setIsOpen(!isOpen)}
              className={`md:hidden hamburger hamburger--squeeze relative ${
                isOpen && 'is-active'
              }`}
              type="button"
              aria-label="Toggle Menu"
            >
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </button>
          </div>
        </div>
        <nav
          className={`mobile-nav ${
            isOpen && 'is-open shadow-lg'
          } z-10 flex flex-col text-center bg-white pt-2 pb-8`}
        >
          <AnchorLink
            to="/#why"
            className="nav-link"
            onAnchorLinkClick={() => setIsOpen(false)}
          >
            Почему&nbsp;мы?
          </AnchorLink>
          <AnchorLink
            to="/#features"
            className="nav-link"
            onAnchorLinkClick={() => setIsOpen(false)}
          >
            Возможности
          </AnchorLink>
          <AnchorLink
            to="/#pricing"
            className="nav-link"
            onAnchorLinkClick={() => setIsOpen(false)}
          >
            Цены
          </AnchorLink>
          <AnchorLink
            to="/#reviews"
            className="nav-link"
            onAnchorLinkClick={() => setIsOpen(false)}
          >
            Отзывы
          </AnchorLink>
          <AnchorLink
            to="/#contact
            "
            className="nav-link"
            onAnchorLinkClick={() => setIsOpen(false)}
          >
            Контакты
          </AnchorLink>
        </nav>
      </header>
      <div className={`overlay ${isOpen && 'is-active'}`} />
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  appStoreLink: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: '',
  appStoreLink: '',
}

export default Header
